<template>
  <router-view />
</template>

<script>


export default {
components: {
}
}
</script>

<style lang="scss">
* {
  margin: 0;
}

*:focus {
  outline: none;
}

@import url("https://fonts.googleapis.com/css2?family=Abel&family=Indie+Flower&family=Noto+Sans&display=swap");

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "Noto Sans", sans-serif;
  // font-family: 'Inter', sans-serif;
  // font-family: "Abel", sans-serif;
  text-align: center;
  color: #1a1a1a;
  font-weight: 400;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #16324f;

    &.router-link-exact-active {
      color: #3e92cc;
    }
  }
}
</style>
